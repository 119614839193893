import React, {useState, useEffect} from "react";
import {Box, Stepper, Step, StepLabel, Button, Typography, Paper} from '@mui/material';
import Header from "../Common/Header/Header";
import Sidenav from "../Common/Sidenav/Sidenav";
import commonService from "../../services/common-service";
import Spinner from "../../shared/Spinner";
import SnackAlert from "../../shared/SnackAlert";
import rateQueryGenerator from "../../helper/rate_query_generator";
import UpdateRateForm from "./UpdateRateForm";
import UpdateRateQuery from "./UpdateRateQuery";
import { useParams } from "react-router-dom";

const steps = ['Add rate detail', 'Insert to dbPIC'];
export default function UpdateRate() {
    const {domain} = useParams();
    const [activeStep, setActiveStep] = React.useState(0);
    const [installations, setInstallations] = useState([]);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [groups, setGroups] = useState([]);
    const [rates, setRates] = useState([]);
    const [tcsIds, setTcsIds] = useState([]);
    const [inputField, setInputField] = useState([]);
    const [inputQuery, setInputQuery] = useState([]);
    const [polygon, setPolygon] = useState([]);
    const [selectedIns, setSelectedIns] = useState(null);
    const [selectedGrp, setSelectedGrp] = useState(null);
    const [spinner, setSpinner] = React.useState(false);
    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChange = (e) =>{
        setInputField({...inputField, [e.target.name]: e.target.value})
    }

    const handleQueryChange=(e) => {
        setInputQuery({...inputQuery, [e.target.name]: e.target.value});
    }

    const getInstalltions=async() => {
        setSpinner(true);
        const user = JSON.parse(sessionStorage.getItem('userLogged'));
        let body = {domain: domain};
        if(user.email == "cwpadmin"){
            body.ins_description = 'cwp'
        }
        const res = await commonService.get_installtions(body);
        setInstallations(res.data);
        setSpinner(false);
    }

    const onInsSelect=async(newValue) => {
        setSpinner(true);
        setSelectedIns(newValue);
        setSelectedGrp(null);
        const res = await commonService.get_groups_by_ins_id({ins_id: newValue.ins_id, domain: domain});
        setGroups(res.data);
        setRates([]);
        setSpinner(false);
    }

    const onGrpSelect=async(newValue) => {
        setSpinner(true);
        setSelectedGrp(newValue);
        const res = await commonService.get_rates_by_grp_id({grp_id: newValue.grp_id, domain: domain});
        if(res.data.polygon !== undefined && res.data.polygon?.length > 0){
            const convertToNum = res.data.polygon.map((x) => {return {lat: parseFloat(x.lat), lng: parseFloat(x.lng)}});
            setPolygon(convertToNum);
        }else{
            setPolygon([])
        }
        var unique = [...new Set(res.data.rate_steps.map(item => item.RAS_RAT_ID))];
        let rate_steps = [];
        unique.map((x,index)=>{
            var newArray = res.data.rate_steps.filter(function (el) {
                return el.RAS_RAT_ID == x
            });
            let dat = res.data.rate_beh.filter((y)=> y.rbss_rat_id == x);
            let unique_dat = [...new Set(dat.map(item => item.DAH_DESCRIPTION))]
            // let dat_arr = []
            // unique_dat.map(z=>{
            //     let a = z.replace('00:00','');
            //     let b = a.replace('24:00','');
            //         dat_arr.push(b);
            // })
            // console.log(unique_dat)
            let arr = {
                RAS_RAT_ID: newArray[0].RAS_RAT_ID,
                RAT_DESCRIPTION: newArray[0].RAT_DESCRIPTION,
                RATE_STEPS: newArray.map(({RAS_AMOUNT,RAS_NUM_MINUTES,RAS_ORDER})=>({RAS_AMOUNT,RAS_NUM_MINUTES,RAS_ORDER})),
                dat_desc: dat[0].DAT_DESCRIPTION,
                dah_desc: unique_dat,
            };
            inputField[arr.RAS_RAT_ID+"_"+index+"_desc"] = arr.RAT_DESCRIPTION
            
            rate_steps.push(arr);
        })
        rate_steps.map((x,index) => {
            x.RATE_STEPS.map((y,z)=>{
                inputField[x.RAS_RAT_ID+"_"+z+"_rate"] = parseInt(y.RAS_AMOUNT)
                inputField[x.RAS_RAT_ID+"_"+z+"_time"] = parseInt(y.RAS_NUM_MINUTES)
            })
        })
        setRates(rate_steps);
        setTcsIds(res.data.tcs_ids);
        setSpinner(false);
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        let rate_detail = []
        rates.map((x,index)=>{
            let obj = {
                rat_id : x.RAS_RAT_ID,
                min_rate : inputField[x.RAS_RAT_ID+"_0_rate"],
                // min_rate : (z==0) ? inputField[x.RAS_RAT_ID+"_"+z+"_rate"] : "",
                max_rate : (x.RATE_STEPS.length > 1) ? inputField[x.RAS_RAT_ID+"_"+(x.RATE_STEPS.length-1)+"_rate"]: "",
                min_time : inputField[x.RAS_RAT_ID+"_0_time"],
                max_time : (x.RATE_STEPS.length > 1) ? inputField[x.RAS_RAT_ID+"_"+(x.RATE_STEPS.length-1)+"_time"]: "",
                rat_desc : inputField[x.RAS_RAT_ID+"_"+index+"_desc"],
            }
            rate_detail.push(obj);
        })
        let rate_steps_query = rateQueryGenerator.UpdateRate(rate_detail,tcsIds);
        setInputQuery(rate_steps_query);
        setSpinner(false);
        handleNext();
    }

    const insertToDB = async () =>{
        setSpinner(true);
        Object.keys(inputQuery).map(function(key, index) {
            inputQuery[key] = inputQuery[key].replace(/\n/g, '');
          });
        inputQuery['domain'] = domain;
        const res = await commonService.insert_to_db(inputQuery);
        if(res.data == true){
            setMsg("Rates updated Successfully");
            setSeverity('success');
        }else{
            setMsg(res.data.map(x=>{return x}).join('\n\n'))
            setSeverity('error');
        }
        setAlert(true);
        setSpinner(false);
    }

    useEffect(()=>{
        getInstalltions();
    },[])

    return (
        <div>
            <Header />
            <div className="main-container my-3">
                <div className="row m-2">
                    <div className="col-lg-2 col-xl-2 d-none d-lg-block d-xl-block">
                        <Sidenav/>
                    </div>
                    <div className="col-lg-10 col-xl-10">
                        <Paper elevation={2} sx={{ width: '100%', p: 3}}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button >Reset</Button>
                                </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {activeStep === 0 && 
                                        <UpdateRateForm
                                            inputField = {inputField}
                                            rates = {rates}
                                            selectedIns = {selectedIns}
                                            installations = {installations}
                                            selectedGrp = {selectedGrp}
                                            groups = {groups}
                                            polygon = {polygon}

                                            onGrpSelect = {(e)=>onGrpSelect(e)}
                                            onInsSelect = {(e)=>onInsSelect(e)}
                                            handleChange = {(e)=>handleChange(e)}
                                            handleSubmit = {(e)=>handleSubmit(e)}
                                        />
                                    }
                                    {activeStep === 1 && 
                                       <>
                                            <UpdateRateQuery
                                                inputQuery = {inputQuery}

                                                handleQueryChange = {(e)=>handleQueryChange(e)}
                                                insertToDB = {(e)=>insertToDB(e)}
                                                handleBack = {()=>handleBack()}
                                            />
                                       </>
                                    }
                                </React.Fragment>
                            )}
                        </Paper>
                    </div>
                </div>
            </div>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </div>
    );
}
