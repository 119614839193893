export class RateQueryGenerator {  
  

  FullDay(inputRate,inputGroup) {
    let total_steps = 1440/inputRate.rat_time;
    let tarif = "INSERT INTO [dbo].[TARIFFS] ([TAR_ID] ,[TAR_DESCRIPTION] ,[TAR_INS_ID])\n" +
                "VALUES ("+inputRate.TAR_ID+",'"+inputGroup.grp_description+"',"+inputGroup.ins_id+")";
    let rate_type = "INSERT INTO [dbo].[RATE_TYPES] ([RAT_ID] ,[RAT_DESCRIPTION] ,[RAT_INS_ID])\n" +
                "VALUES ("+inputRate.RAT_ID+",'"+inputGroup.grp_description+" Rate',"+inputGroup.ins_id+")";
    let rate_steps = "";
    let day_max_rate;
    if(inputRate.is_first_hour){
      rate_steps = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(inputRate.day_first_rate)/100+" = "+inputRate.day_first_rate_time+" min',"+inputRate.RAT_ID+","+1+","+(inputRate.day_first_rate)+","+inputRate.day_first_rate_time+",3 ,0)";
    }
    for(let i = 0; i < total_steps; i++){
      let time = inputRate.rat_time * (i+1) + (inputRate.is_first_hour ? parseInt(inputRate.day_first_rate_time) : 0);
      let rate = inputRate.rat_price * (i+1) + (inputRate.is_first_hour ? parseInt(inputRate.day_first_rate) : 0);
      if(time >= 1440){
        time = 1440;
      }
      let rate_step = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(rate)/100+" = "+time+" min',"+inputRate.RAT_ID+","+(i+(inputRate.is_first_hour ? 2 : 1))+","+rate+","+time+",3 ,0)";
      rate_steps = rate_steps.concat("\n\n", rate_step)
      day_max_rate = rate;
      if(time >= 1440)
        break;
    }
    let update_rat_type = "UPDATE [dbo].[RATE_TYPES]\n"+
    "SET [RAT_REPEAT_FROM_RAS_ID_INFINITELY] = (SELECT RAS_ID from RATE_STEPS WHERE RAS_RAT_ID = "+inputRate.RAT_ID+" AND RAS_ORDER = 1)\n"+
    "WHERE RAT_ID = "+inputRate.RAT_ID+""
    rate_steps = rate_steps.concat("\n\n", update_rat_type)
    let rate_behaviour_set = "INSERT INTO [dbo].[RATE_BEHAVIOR_SETS] ([RBS_ID] ,[RBS_DESCRIPTION] ,[RBS_INS_ID])\n" +
                "VALUES("+inputRate.RBS_ID+",'"+inputGroup.grp_description+"',"+inputGroup.ins_id+")";
    let day_hour_interval = "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate.DAH_ID+",'00:00 - 24:00','00:00' ,'24:00',"+inputGroup.ins_id+")";
    let day_types = "INSERT INTO [dbo].[DAY_TYPES] ([DAT_ID] ,[DAT_DESCRIPTION] ,[DAT_WEEK_MASK] ,[DAT_INS_ID])\n" +
                "VALUES ("+inputRate.DAT_ID+",'Monday to Sunday',1111111,"+inputGroup.ins_id+")";
    let rate_behaviour_step = "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 00:00 - 24:00',"+inputRate.RBS_ID+"	,1 ,"+inputRate.RAT_ID+","+inputRate.DAT_ID+" ,null ,null ,"+inputRate.DAH_ID+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)";
    let tariff_constraint_set = "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS] ([TCS_ID] ,[TCS_DESCRIPTION] ,[TCS_INS_ID])\n" +
                "VALUES ("+inputRate.TCS_ID+",'"+inputGroup.grp_description+"',"+inputGroup.ins_id+")";
    let tariff_constraint_enteries = "INSERT INTO [dbo].[TARIFF_CONSTRAINT_ENTRIES] ([TCE_ID],[TCE_DESCRIPTION],[TCE_TCS_ID],[TCE_GRP_ID],[TCE_GRPT_ID],[TCE_MAX_PARKING_TIME],[TCE_MIN_PARKING_AMOUNT],[TCE_MAX_PARKING_AMOUNT],[TCE_PARALEL_PARKING_ALLOWED],[TCE_QUERY_HISTORY_OPERATIONS],[TCE_COURTESY_TIME_IN_EXTENSION],[TCE_COURTESY_TIME_MUST_BE_PAID],[TCE_ALLOW_EXTENSIONS],[TCE_ALLOW_REFUNDS],[TCE_EXTENSION_MAX_SLIDING_WINDOW],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_EXTENSIONS],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_REFUNDS],[TCE_MIN_REENTRY_TIME],[TCE_NUM_TARIFF_MINUTES_FOR_NOT_APP_REENTRY],[TCE_INTRA_GROUP_PARK],[TCE_INTRA_GROUP_PARK_COURTESY_TIME])\n"+
                "VALUES("+inputRate.TCE_ID+",'"+inputGroup.grp_description+" Rate',"+inputRate.TCS_ID+" ,null,null,1440,"+inputRate.rat_price+","+day_max_rate+",0,2,0,0,1,0,1,0,0,0,0,0,0)";
    let tariff_definition_rules = "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Mon-Sun 00:00 - 24:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.TCS_ID+" ,'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.DAT_ID+" ,"+inputRate.DAH_ID+"	,null,null)";
    let tariff_application_rules = "INSERT INTO [dbo].[TARIFFS_APPLICATION_RULES]([TAPR_DESCRIPTON],[TAPR_TAR_ID],[TAPR_UNI_ID],[TAPR_GRP_ID],[TAPR_GRPT_ID],[TAPR_INI_APPLY_DATE],[TAPR_END_APPLY_DATE],[TAPR_DAT_ID],[TAPR_DAH_ID],[TARP_IN_DAY_INI_HOUR],[TARP_IN_DAY_END_HOUR],[TAPR_IN_YEAR_INI_DATE],[TAPR_IN_YEAR_END_DATE],[TAPR_SHOW_ORDER],[TAPR_ALLOW_USER_SELECT],[TAPR_INSERT_PLATE],[TAPR_SPACE_MANAGMENT_TYPE],[TAPR_TCS_ID],[TAPR_PLATE_TARIFF_ASSIGN_TYPE],[TAPR_TIMEAMOUNT_SEL_TYPE],[TAPR_PAY_MODE],[TAPR_INC1],[TAPR_INC2],[TAPR_INC3],[TAPR_INC4],[TAPR_INC5],[TAPR_DEC1],[TAPR_DEC2],[TAPR_DEC3],[TAPR_DEC4],[TAPR_DEC5],[TAPR_ACCESS_CARD_TYPE],[TAPR_ACCESS_CARD_NUM],[TAPR_CONNECTION_REQUIRED],[TAPR_ALLOW_REMOTE_EXTENSION],[TAPR_TARIFF_ENGINE],[TAPR_BNF_ID],[TAPR_ALLOW_VOUCHERS],[TAPR_VOUCHER_ASSIGN_TYPE],[TAPR_VOUCHER_ASSIGN_VALUE],[TAPR_PERMITTED_ASSIGNED_TARIFFS],[TAPR_AUTO_ASSIGNED_TARIFFS],[TAPR_COMPUTESTEPS_TIMEAMOUNT],[TAPR_COMPUTESTEPS_OFFSET],[TAPR_ALLOW_STEP_OVERPAY],[TAPR_ALLOWED_PAYMENT_METHODS])\n"+
              "VALUES('"+inputGroup.grp_description+"',"+inputRate.TAR_ID+",null,"+inputGroup.grp_id+",null,'2019-03-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,null,null,null,null,1,1,1,0,"+inputRate.TCS_ID+",1,0,0,5,10,30,60,120,5,10,30,60,120,null,null,null,0,5,null,0,null,null,null,null,null,null,0,null)";
    let integra_tarif = "INSERT INTO [dbo].[TARIFFS]([TAR_ID],[TAR_DESCRIPTION],[TAR_LIT_ID],[TAR_QUERY_EXT_ID],[TAR_EXT1_ID],[TAR_EXT2_ID],[TAR_EXT3_ID],[TAR_ID_FOR_EXT_OPS],[TAR_INS_ID],[TAR_MIN_COIN],[TAR_TYPE],[TAR_PERMIT_RENEWAL_DAY],[TAR_MAX_PLATES],[TAR_MAX_PERMITS_ONCE],[TAR_BEHAVIOR],[TAR_APP_MIN_VERSION],[TAR_APP_MAX_VERSION],[TAR_SERTYP_ID],[TAR_AUTOSTART],[TAR_RESTART_TARIFF],[TAR_NOTICE_CHARGES_NOW],[TAR_NOTICE_CHARGES_NOW_LIT_ID],[TAR_SHOPKEEPER_BEHAVIOR_TYPE])\n"+
    "VALUES("+inputRate.integra_tar_id+",'"+inputGroup.grp_description+"',"+(inputRate.integra_lit_id+1)+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.integra_ins_id+",null,0,null,1,null,0,null,null,null,null,null,1,100,0)";

    let integra_tarif_in_group = "INSERT INTO [dbo].[TARIFFS_IN_GROUPS]([TARGR_GRP_ID],[TARGR_GRPT_ID],[TARGR_TAR_ID],[TARGR_TIME_STEPS_VALUE],[TARGR_LIT_ID],[TARGR_USER_SELECTABLE],[TARGR_INI_APPLY_DATE],[TARGR_END_APPLY_DATE],[TARGR_STEP1_MIN],[TARGR_STEP1_LIT_ID],[TARGR_STEP1_ENABLED],[TARGR_STEP2_MIN],[TARGR_STEP2_LIT_ID],[TARGR_STEP2_ENABLED],[TARGR_STEP3_MIN],[TARGR_STEP3_LIT_ID],[TARGR_STEP3_ENABLED],[TARGR_STEP4_MIN],[TARGR_STEP4_LIT_ID],[TARGR_STEP4_ENABLED],[TARGR_STEP5_MIN],[TARGR_STEP5_LIT_ID],[TARGR_STEP5_ENABLED],[TARGR_STEP6_MIN],[TARGR_STEP6_LIT_ID],[TARGR_STEP6_ENABLED],[TARGR_STEP7_MIN],[TARGR_STEP7_LIT_ID],[TARGR_STEP7_ENABLED],[TARGR_STEP8_MIN],[TARGR_STEP8_LIT_ID],[TARGR_STEP8_ENABLED],[TARGR_STEP9_MIN],[TARGR_STEP9_LIT_ID],[TARGR_STEP9_ENABLED],[TARGR_STEP10_MIN],[TARGR_STEP10_LIT_ID],[TARGR_STEP10_ENABLED],[TARGR_LAYOUT],[TARGR_PARKING_MODE],[TARGR_TAR_INFO2_LIT_ID],[TARGR_TAR_INFO3_LIT_ID],[TARGR_TAR_INFO4_LIT_ID],[TARGR_TAR_INFO5_LIT_ID],[TARGR_TAR_INFO6_LIT_ID],[TARGR_TAR_INFO7_LIT_ID],[TARGR_TAR_INFO8_LIT_ID],[TARGR_TAR_INFO9_LIT_ID],[TARGR_TAR_INFO10_LIT_ID],[TARGR_INCLUDE_ZERO_STEP],[TARGR_TIME_DEF_STEP],[TARGR_POLYGON_SHOW],[TARGR_POLYGON_COLOUR],[TARGR_POLYGON_Z],[TARGR_POLYGON_MAP_DESCRIPTION])\n" +
    "VALUES("+inputRate.integra_grp_id+",null,"+inputRate.integra_tar_id+",5,"+(inputRate.integra_lit_id+1)+",1,'2021-06-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,1,null,null,1,null,null,1,null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,null,0,null,null,null,1,1,1,null,null,null,null,null,null,null,null,null,0,null,null,null,null,null)";
     
    let ticket_grhi = "INSERT INTO GROUPS_HIERARCHY (GRHI_ID, GRHI_GRP_ID, GRHI_INI_APPLY_DATE, GRHI_END_APPLY_DATE)\n"+
    "VALUES ("+inputRate.ticket_grhi_id+","+inputRate.ticket_grp_id+", TO_DATE('12/16/2021', 'mm/dd/yyyy') ,TO_DATE('1/1/2050', 'mm/dd/yyyy'));"
    
    let lastQue = '';
    let queryObj = {
      tarif: tarif, 
      rate_type: rate_type, 
      rate_steps: rate_steps,
      rate_behaviour_set: rate_behaviour_set, 
      day_hour_interval: day_hour_interval,
      day_types: day_types, 
      rate_behaviour_step: rate_behaviour_step, 
      tariff_constraint_set: tariff_constraint_set,
      tariff_constraint_enteries: tariff_constraint_enteries, 
      tariff_definition_rules: tariff_definition_rules, 
      tariff_application_rules: tariff_application_rules
    }
    let integraObj = {
      // integra_lit: integra_lit,
      integra_tarif: integra_tarif,
      integra_tarif_in_group: integra_tarif_in_group
    }
    let ticketObj = {
      ticket_grhi: ticket_grhi
    }
    
    return {
        query: lastQue.concat(tarif, rate_type, rate_steps, rate_behaviour_set, day_hour_interval,
        day_types, rate_behaviour_step, tariff_constraint_set, tariff_constraint_enteries, tariff_definition_rules, 
        tariff_application_rules),
        queryObj: queryObj,
        integraObj: integraObj,
        ticketObj: ticketObj
      }
    }

  ThreeContinousRate(inputRate,inputGroup) {
    let steps_by_rate = Math.round(inputRate.day_max_rate_time/inputRate.day_min_rate_time) - (inputRate.is_first_hour ? 1 : 0);
    let total_steps = 720/inputRate.day_rate_time;
    let tarif = "INSERT INTO [dbo].[TARIFFS] ([TAR_ID] ,[TAR_DESCRIPTION] ,[TAR_INS_ID])\n" +
                "VALUES ("+inputRate.TAR_ID+",'"+inputGroup.grp_description+"',"+inputGroup.ins_id+")";
    let rate_type = "INSERT INTO [dbo].[RATE_TYPES] ([RAT_ID] ,[RAT_DESCRIPTION] ,[RAT_INS_ID])\n" +
                "VALUES ("+inputRate.Day_RAT_ID+",'"+inputGroup.grp_description+" Mon-Fri Rate',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[RATE_TYPES] ([RAT_ID] ,[RAT_DESCRIPTION] ,[RAT_INS_ID])\n" +
                "VALUES ("+inputRate.Even_RAT_ID+",'"+inputGroup.grp_description+" Evening Rate',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[RATE_TYPES] ([RAT_ID] ,[RAT_DESCRIPTION] ,[RAT_INS_ID])\n" +
                "VALUES ("+inputRate.Weekend_RAT_ID+",'"+inputGroup.grp_description+" Weekend Rate',"+inputGroup.ins_id+")";
    let rate_steps = "";
    let day_max_rate;
    if(inputRate.is_first_hour){
      rate_steps = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(inputRate.day_first_rate)/100+" = "+inputRate.day_first_rate_time+" min',"+inputRate.Day_RAT_ID+","+1+","+(inputRate.day_first_rate)+","+inputRate.day_first_rate_time+",3 ,0)";
    }
    for(let i = 0; i < steps_by_rate; i++){
      let time = inputRate.day_rate_time * (i+1) + (inputRate.is_first_hour ? parseInt(inputRate.day_first_rate_time) : 0);
      let rate = inputRate.day_min_rate_time * (i+1) + (inputRate.is_first_hour ? parseInt(inputRate.day_first_rate) : 0);
      if(rate >= inputRate.day_max_rate_time){
        rate = inputRate.day_max_rate_time;
        time = 720;
      }
      let rate_step = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(rate)/100+" = "+time+" min',"+inputRate.Day_RAT_ID+","+(i+(inputRate.is_first_hour ? 2 : 1))+","+(rate)+","+time+",3 ,0)";
      rate_steps = rate_steps.concat("\n\n", rate_step)
      day_max_rate = rate;
    }
    if(inputRate.day_max_rate_time/inputRate.day_min_rate_time % 1){
      rate_steps = rate_steps.concat("\n", 
              "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
              "VALUES ('$"+(inputRate.day_max_rate_time)/100+" = 720 min',"+inputRate.Day_RAT_ID+","+(steps_by_rate+1)+","+(inputRate.day_max_rate_time)+",720,3 ,0)"
              )
    }
    let weekend_evening_step = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(inputRate.evening_rate)/100+" = 720 min',"+inputRate.Even_RAT_ID+",1,"+(inputRate.evening_rate)+",720,3 ,0)\n\n" + 
                "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(inputRate.weekend_rate)/100+" = 720 min',"+inputRate.Weekend_RAT_ID+",1,"+(inputRate.weekend_rate)+",720,3 ,0)";
    rate_steps = rate_steps.concat("\n\n", weekend_evening_step);

    
    let day_hour_interval = "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate['12AM_6AM_DAH_ID']+",'00:00 - 06:00','00:00' ,'06:00',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate['6AM_6PM_DAH_ID']+",'06:00 - 18:00','06:00' ,'18:00',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate['6PM_12PM_DAH_ID']+",'18:00 - 24:00','18:00' ,'24:00',"+inputGroup.ins_id+")";

    let day_types = "INSERT INTO [dbo].[DAY_TYPES] ([DAT_ID] ,[DAT_DESCRIPTION] ,[DAT_WEEK_MASK] ,[DAT_INS_ID])\n" +
                "VALUES ("+inputRate.Day_DAT_ID+",'Monday to Friday',1111100,"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[DAY_TYPES] ([DAT_ID] ,[DAT_DESCRIPTION] ,[DAT_WEEK_MASK] ,[DAT_INS_ID])\n" +
                "VALUES ("+inputRate.Even_DAT_ID+",'Monday to Sunday',1111111,"+inputGroup.ins_id+")\n\n" + 
                "INSERT INTO [dbo].[DAY_TYPES] ([DAT_ID] ,[DAT_DESCRIPTION] ,[DAT_WEEK_MASK] ,[DAT_INS_ID])\n" +
                "VALUES ("+inputRate.Weekend_DAT_ID+",'Saturday to Sunday',0000011,"+inputGroup.ins_id+")";
    
    let rate_behaviour_set = "INSERT INTO [dbo].[RATE_BEHAVIOR_SETS] ([RBS_ID] ,[RBS_DESCRIPTION] ,[RBS_INS_ID])\n" +
                "VALUES("+inputRate.RBS_ID+",'"+inputGroup.grp_description+"',"+inputGroup.ins_id+")";
    let rate_behaviour_step = "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 00:00 - 06:00',"+inputRate.RBS_ID+"	,1 ,"+inputRate.Even_RAT_ID+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['12AM_6AM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Fri 06:00 - 18:00',"+inputRate.RBS_ID+"	,2 ,"+inputRate.Day_RAT_ID+","+inputRate.Day_DAT_ID+" ,null ,null ,"+inputRate['6AM_6PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Sat-Sun 06:00 - 18:00',"+inputRate.RBS_ID+"	,3 ,"+inputRate.Weekend_RAT_ID+","+inputRate.Weekend_DAT_ID+" ,null ,null ,"+inputRate['6AM_6PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 06:00 - 24:00',"+inputRate.RBS_ID+"	,4 ,"+inputRate.Even_RAT_ID+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['6PM_12PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)";
    
    let tariff_constraint_set = "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS] ([TCS_ID] ,[TCS_DESCRIPTION] ,[TCS_INS_ID])\n" +
                "VALUES ("+inputRate.Day_TCS_ID+",'"+inputGroup.grp_description+" Mon-Fri Rate',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS] ([TCS_ID] ,[TCS_DESCRIPTION] ,[TCS_INS_ID])\n" +
                "VALUES ("+inputRate.Even_TCS_ID+",'"+inputGroup.grp_description+" Evening Rate',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS] ([TCS_ID] ,[TCS_DESCRIPTION] ,[TCS_INS_ID])\n" +
                "VALUES ("+inputRate.Weekend_TCS_ID+",'"+inputGroup.grp_description+" Weekend Rate',"+inputGroup.ins_id+")";
    
    let tariff_constraint_enteries = "INSERT INTO [dbo].[TARIFF_CONSTRAINT_ENTRIES] ([TCE_ID],[TCE_DESCRIPTION],[TCE_TCS_ID],[TCE_GRP_ID],[TCE_GRPT_ID],[TCE_MAX_PARKING_TIME],[TCE_MIN_PARKING_AMOUNT],[TCE_MAX_PARKING_AMOUNT],[TCE_PARALEL_PARKING_ALLOWED],[TCE_QUERY_HISTORY_OPERATIONS],[TCE_COURTESY_TIME_IN_EXTENSION],[TCE_COURTESY_TIME_MUST_BE_PAID],[TCE_ALLOW_EXTENSIONS],[TCE_ALLOW_REFUNDS],[TCE_EXTENSION_MAX_SLIDING_WINDOW],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_EXTENSIONS],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_REFUNDS],[TCE_MIN_REENTRY_TIME],[TCE_NUM_TARIFF_MINUTES_FOR_NOT_APP_REENTRY],[TCE_INTRA_GROUP_PARK],[TCE_INTRA_GROUP_PARK_COURTESY_TIME])\n"+
                "VALUES("+inputRate.Day_TCE_ID+",'"+inputGroup.grp_description+" Mon-Fri Rate',"+inputRate.Day_TCS_ID+" ,null,null,1440,"+inputRate.day_min_rate_time+","+(parseInt(day_max_rate)+parseInt(inputRate.evening_rate)+parseInt(inputRate.weekend_rate))+",0,2,0,0,1,0,1,0,0,0,0,0,0)\n\n" +
                "INSERT INTO [dbo].[TARIFF_CONSTRAINT_ENTRIES] ([TCE_ID],[TCE_DESCRIPTION],[TCE_TCS_ID],[TCE_GRP_ID],[TCE_GRPT_ID],[TCE_MAX_PARKING_TIME],[TCE_MIN_PARKING_AMOUNT],[TCE_MAX_PARKING_AMOUNT],[TCE_PARALEL_PARKING_ALLOWED],[TCE_QUERY_HISTORY_OPERATIONS],[TCE_COURTESY_TIME_IN_EXTENSION],[TCE_COURTESY_TIME_MUST_BE_PAID],[TCE_ALLOW_EXTENSIONS],[TCE_ALLOW_REFUNDS],[TCE_EXTENSION_MAX_SLIDING_WINDOW],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_EXTENSIONS],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_REFUNDS],[TCE_MIN_REENTRY_TIME],[TCE_NUM_TARIFF_MINUTES_FOR_NOT_APP_REENTRY],[TCE_INTRA_GROUP_PARK],[TCE_INTRA_GROUP_PARK_COURTESY_TIME])\n"+
                "VALUES("+inputRate.Even_TCE_ID+",'"+inputGroup.grp_description+" Evening Rate',"+inputRate.Even_TCS_ID+" ,null,null,1440,"+(inputRate.evening_rate)+","+(parseInt(day_max_rate)+parseInt(inputRate.evening_rate)+parseInt(inputRate.weekend_rate))+",0,2,0,0,1,0,1,0,0,0,0,0,0)\n\n" +
                "INSERT INTO [dbo].[TARIFF_CONSTRAINT_ENTRIES] ([TCE_ID],[TCE_DESCRIPTION],[TCE_TCS_ID],[TCE_GRP_ID],[TCE_GRPT_ID],[TCE_MAX_PARKING_TIME],[TCE_MIN_PARKING_AMOUNT],[TCE_MAX_PARKING_AMOUNT],[TCE_PARALEL_PARKING_ALLOWED],[TCE_QUERY_HISTORY_OPERATIONS],[TCE_COURTESY_TIME_IN_EXTENSION],[TCE_COURTESY_TIME_MUST_BE_PAID],[TCE_ALLOW_EXTENSIONS],[TCE_ALLOW_REFUNDS],[TCE_EXTENSION_MAX_SLIDING_WINDOW],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_EXTENSIONS],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_REFUNDS],[TCE_MIN_REENTRY_TIME],[TCE_NUM_TARIFF_MINUTES_FOR_NOT_APP_REENTRY],[TCE_INTRA_GROUP_PARK],[TCE_INTRA_GROUP_PARK_COURTESY_TIME])\n"+
                "VALUES("+inputRate.Weekend_TCE_ID+",'"+inputGroup.grp_description+" Weekend Rate',"+inputRate.Weekend_TCS_ID+" ,null,null,1440,"+(inputRate.weekend_rate)+","+(parseInt(day_max_rate)+parseInt(inputRate.evening_rate)+parseInt(inputRate.weekend_rate))+",0,2,0,0,1,0,1,0,0,0,0,0,0)\n\n";

    let tariff_definition_rules = "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Mon-Sun 00:00 - 06:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.Even_TCS_ID+",'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.Even_DAT_ID+","+inputRate['12AM_6AM_DAH_ID']+",null,null)\n\n" +
                "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Mon-Fri 06:00 - 18:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.Day_TCS_ID+",'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.Day_DAT_ID+","+inputRate['6AM_6PM_DAH_ID']+",null,null)\n\n" +
                "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Sat-Sun 06:00 - 18:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.Weekend_TCS_ID+",'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.Weekend_DAT_ID+","+inputRate['6AM_6PM_DAH_ID']+",null,null)\n\n" +
                "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Mon-Sun 18:00-24:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.Even_TCS_ID+",'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.Even_DAT_ID+","+inputRate['6PM_12PM_DAH_ID']+",null,null)";

    let tariff_application_rules = "INSERT INTO [dbo].[TARIFFS_APPLICATION_RULES]([TAPR_DESCRIPTON],[TAPR_TAR_ID],[TAPR_UNI_ID],[TAPR_GRP_ID],[TAPR_GRPT_ID],[TAPR_INI_APPLY_DATE],[TAPR_END_APPLY_DATE],[TAPR_DAT_ID],[TAPR_DAH_ID],[TARP_IN_DAY_INI_HOUR],[TARP_IN_DAY_END_HOUR],[TAPR_IN_YEAR_INI_DATE],[TAPR_IN_YEAR_END_DATE],[TAPR_SHOW_ORDER],[TAPR_ALLOW_USER_SELECT],[TAPR_INSERT_PLATE],[TAPR_SPACE_MANAGMENT_TYPE],[TAPR_TCS_ID],[TAPR_PLATE_TARIFF_ASSIGN_TYPE],[TAPR_TIMEAMOUNT_SEL_TYPE],[TAPR_PAY_MODE],[TAPR_INC1],[TAPR_INC2],[TAPR_INC3],[TAPR_INC4],[TAPR_INC5],[TAPR_DEC1],[TAPR_DEC2],[TAPR_DEC3],[TAPR_DEC4],[TAPR_DEC5],[TAPR_ACCESS_CARD_TYPE],[TAPR_ACCESS_CARD_NUM],[TAPR_CONNECTION_REQUIRED],[TAPR_ALLOW_REMOTE_EXTENSION],[TAPR_TARIFF_ENGINE],[TAPR_BNF_ID],[TAPR_ALLOW_VOUCHERS],[TAPR_VOUCHER_ASSIGN_TYPE],[TAPR_VOUCHER_ASSIGN_VALUE],[TAPR_PERMITTED_ASSIGNED_TARIFFS],[TAPR_AUTO_ASSIGNED_TARIFFS],[TAPR_COMPUTESTEPS_TIMEAMOUNT],[TAPR_COMPUTESTEPS_OFFSET],[TAPR_ALLOW_STEP_OVERPAY],[TAPR_ALLOWED_PAYMENT_METHODS])\n"+
              "VALUES('"+inputGroup.grp_description+"',"+inputRate.TAR_ID+",null,"+inputGroup.grp_id+",null,'2019-03-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,null,null,null,null,1,1,1,0,"+inputRate.Day_TCS_ID+",1,0,0,5,10,30,60,120,5,10,30,60,120,null,null,null,0,5,null,0,null,null,null,null,null,null,0,null)";

    let integra_tarif = "INSERT INTO [dbo].[TARIFFS]([TAR_ID],[TAR_DESCRIPTION],[TAR_LIT_ID],[TAR_QUERY_EXT_ID],[TAR_EXT1_ID],[TAR_EXT2_ID],[TAR_EXT3_ID],[TAR_ID_FOR_EXT_OPS],[TAR_INS_ID],[TAR_MIN_COIN],[TAR_TYPE],[TAR_PERMIT_RENEWAL_DAY],[TAR_MAX_PLATES],[TAR_MAX_PERMITS_ONCE],[TAR_BEHAVIOR],[TAR_APP_MIN_VERSION],[TAR_APP_MAX_VERSION],[TAR_SERTYP_ID],[TAR_AUTOSTART],[TAR_RESTART_TARIFF],[TAR_NOTICE_CHARGES_NOW],[TAR_NOTICE_CHARGES_NOW_LIT_ID],[TAR_SHOPKEEPER_BEHAVIOR_TYPE])\n"+
         "VALUES("+inputRate.integra_tar_id+",'"+inputGroup.grp_description+"',"+(inputRate.integra_lit_id+1)+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.integra_ins_id+",null,0,null,1,null,0,null,null,null,null,null,1,100,0)";
        
    let integra_tarif_in_group = "INSERT INTO [dbo].[TARIFFS_IN_GROUPS]([TARGR_GRP_ID],[TARGR_GRPT_ID],[TARGR_TAR_ID],[TARGR_TIME_STEPS_VALUE],[TARGR_LIT_ID],[TARGR_USER_SELECTABLE],[TARGR_INI_APPLY_DATE],[TARGR_END_APPLY_DATE],[TARGR_STEP1_MIN],[TARGR_STEP1_LIT_ID],[TARGR_STEP1_ENABLED],[TARGR_STEP2_MIN],[TARGR_STEP2_LIT_ID],[TARGR_STEP2_ENABLED],[TARGR_STEP3_MIN],[TARGR_STEP3_LIT_ID],[TARGR_STEP3_ENABLED],[TARGR_STEP4_MIN],[TARGR_STEP4_LIT_ID],[TARGR_STEP4_ENABLED],[TARGR_STEP5_MIN],[TARGR_STEP5_LIT_ID],[TARGR_STEP5_ENABLED],[TARGR_STEP6_MIN],[TARGR_STEP6_LIT_ID],[TARGR_STEP6_ENABLED],[TARGR_STEP7_MIN],[TARGR_STEP7_LIT_ID],[TARGR_STEP7_ENABLED],[TARGR_STEP8_MIN],[TARGR_STEP8_LIT_ID],[TARGR_STEP8_ENABLED],[TARGR_STEP9_MIN],[TARGR_STEP9_LIT_ID],[TARGR_STEP9_ENABLED],[TARGR_STEP10_MIN],[TARGR_STEP10_LIT_ID],[TARGR_STEP10_ENABLED],[TARGR_LAYOUT],[TARGR_PARKING_MODE],[TARGR_TAR_INFO2_LIT_ID],[TARGR_TAR_INFO3_LIT_ID],[TARGR_TAR_INFO4_LIT_ID],[TARGR_TAR_INFO5_LIT_ID],[TARGR_TAR_INFO6_LIT_ID],[TARGR_TAR_INFO7_LIT_ID],[TARGR_TAR_INFO8_LIT_ID],[TARGR_TAR_INFO9_LIT_ID],[TARGR_TAR_INFO10_LIT_ID],[TARGR_INCLUDE_ZERO_STEP],[TARGR_TIME_DEF_STEP],[TARGR_POLYGON_SHOW],[TARGR_POLYGON_COLOUR],[TARGR_POLYGON_Z],[TARGR_POLYGON_MAP_DESCRIPTION])\n" +
    "VALUES("+inputRate.integra_grp_id+",null,"+inputRate.integra_tar_id+",5,"+(inputRate.integra_lit_id+1)+",1,'2021-06-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,1,null,null,1,null,null,1,null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,null,0,null,null,null,1,1,1,null,null,null,null,null,null,null,null,null,0,null,null,null,null,null)";

    let ticket_grhi = "INSERT INTO GROUPS_HIERARCHY (GRHI_ID, GRHI_GRP_ID, GRHI_INI_APPLY_DATE, GRHI_END_APPLY_DATE)\n"+
    "VALUES ("+inputRate.ticket_grhi_id+","+inputRate.ticket_grp_id+", TO_DATE('12/16/2021', 'mm/dd/yyyy') ,TO_DATE('1/1/2050', 'mm/dd/yyyy'));"
    
    let lastQue = '';
    let queryObj = {
      tarif: tarif, 
      rate_type: rate_type, 
      rate_steps: rate_steps,
      rate_behaviour_set: rate_behaviour_set, 
      day_hour_interval: day_hour_interval,
      day_types: day_types, 
      rate_behaviour_step: rate_behaviour_step, 
      tariff_constraint_set: tariff_constraint_set,
      tariff_constraint_enteries: tariff_constraint_enteries, 
      tariff_definition_rules: tariff_definition_rules, 
      tariff_application_rules: tariff_application_rules
    }
    let integraObj = {
      // integra_lit: integra_lit,
      integra_tarif: integra_tarif,
      integra_tarif_in_group: integra_tarif_in_group
    }
    let ticketObj = {
      ticket_grhi: ticket_grhi
    }
    return {
      query: lastQue.concat(tarif, rate_type, rate_steps, rate_behaviour_set, day_hour_interval,
       day_types, rate_behaviour_step, tariff_constraint_set, tariff_constraint_enteries, tariff_definition_rules, 
       tariff_application_rules),
      queryObj: queryObj,
      integraObj: integraObj,
      ticketObj: ticketObj
    }
  }

  OverlappedRate(inputRate,inputGroup) {
    console.log(inputRate)
    let steps_by_rate = Math.round(inputRate.day_max_rate_time/inputRate.day_min_rate_time)
    let tarif = "INSERT INTO [dbo].[TARIFFS] ([TAR_ID] ,[TAR_DESCRIPTION] ,[TAR_INS_ID])\n" +
                "VALUES ("+inputRate.TAR_ID+",'"+inputGroup.grp_description+"',"+inputGroup.ins_id+")";
    let rate_type = "INSERT INTO [dbo].[RATE_TYPES] ([RAT_ID] ,[RAT_DESCRIPTION] ,[RAT_INS_ID])\n" +
                "VALUES ("+inputRate.Day_RAT_ID+",'"+inputGroup.grp_description+" Mon-Fri Rate',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[RATE_TYPES] ([RAT_ID] ,[RAT_DESCRIPTION] ,[RAT_INS_ID])\n" +
                "VALUES ("+inputRate.Even_RAT_ID+",'"+inputGroup.grp_description+" Evening Rate',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[RATE_TYPES] ([RAT_ID] ,[RAT_DESCRIPTION] ,[RAT_INS_ID])\n" +
                "VALUES ("+(inputRate.Even_RAT_ID+2)+",'"+inputGroup.grp_description+" Evening Rate II',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[RATE_TYPES] ([RAT_ID] ,[RAT_DESCRIPTION] ,[RAT_INS_ID])\n" +
                "VALUES ("+inputRate.Weekend_RAT_ID+",'"+inputGroup.grp_description+" Weekend Rate',"+inputGroup.ins_id+")";
    let rate_steps = "";
    let day_max_rate;
    for(let i = 0; i < steps_by_rate; i++){
      let time = inputRate.day_rate_time * (i+1);
      let rate = inputRate.day_min_rate_time * (i+1)
      if(rate == inputRate.day_max_rate_time){
        time = 720;
      }
      let rate_step = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(rate)/100+" = "+time+" min',"+inputRate.Day_RAT_ID+","+(i+1)+","+(rate)+","+time+",3 ,0)";
      rate_steps = rate_steps.concat("\n\n", rate_step)
      day_max_rate = rate;
    }
    if(inputRate.day_max_rate_time/inputRate.day_min_rate_time % 1){
      rate_steps = rate_steps.concat("\n", 
              "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
              "VALUES ('$"+(inputRate.day_max_rate_time)/100+" = 720 min',"+inputRate.Day_RAT_ID+","+(steps_by_rate+1)+","+(inputRate.day_max_rate_time)+",720,3 ,0)"
              )
    }
    let weekend_evening_step = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(inputRate.evening_rate)/100+" = 720 min',"+inputRate.Even_RAT_ID+",1,"+(inputRate.evening_rate)+",720,3 ,0)\n\n" + 
                "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(inputRate.evening_rate)/100+" = 780 min',"+(inputRate.Even_RAT_ID+2)+",1,"+(inputRate.evening_rate)+",780,3 ,0)\n\n" + 
                "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                "VALUES ('$"+(inputRate.weekend_rate)/100+" = 720 min',"+inputRate.Weekend_RAT_ID+",1,"+(inputRate.weekend_rate)+",720,3 ,0)";
    rate_steps = rate_steps.concat("\n\n", weekend_evening_step);

    
    let day_hour_interval = "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate['12AM_6AM_DAH_ID']+",'00:00 - 06:00','00:00' ,'06:00',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate['6AM_6PM_DAH_ID']+",'06:00 - 18:00','06:00' ,'18:00',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate['5PM_12PM_DAH_ID']+",'17:00 - 24:00','17:00' ,'24:00',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate['6PM_12PM_DAH_ID']+",'18:00 - 24:00','18:00' ,'24:00',"+inputGroup.ins_id+")"+
                "INSERT INTO [dbo].[DAY_HOURS_INTERVALS] ([DAH_ID], [DAH_DESCRIPTION], [DAH_INI_HOUR], [DAH_END_HOUR], [DAH_INS_ID])\n" +
                "VALUES ("+inputRate['6AM_5PM_DAH_ID']+",'06:00 - 17:00','06:00' ,'17:00',"+inputGroup.ins_id+")";

    let day_types = "INSERT INTO [dbo].[DAY_TYPES] ([DAT_ID] ,[DAT_DESCRIPTION] ,[DAT_WEEK_MASK] ,[DAT_INS_ID])\n" +
                "VALUES ("+inputRate.Day_DAT_ID+",'Monday to Friday',1111100,"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[DAY_TYPES] ([DAT_ID] ,[DAT_DESCRIPTION] ,[DAT_WEEK_MASK] ,[DAT_INS_ID])\n" +
                "VALUES ("+inputRate.Even_DAT_ID+",'Monday to Sunday',1111111,"+inputGroup.ins_id+")\n\n" + 
                "INSERT INTO [dbo].[DAY_TYPES] ([DAT_ID] ,[DAT_DESCRIPTION] ,[DAT_WEEK_MASK] ,[DAT_INS_ID])\n" +
                "VALUES ("+inputRate.Weekend_DAT_ID+",'Saturday to Sunday',0000011,"+inputGroup.ins_id+")";
    
    let rate_behaviour_set = "INSERT INTO [dbo].[RATE_BEHAVIOR_SETS] ([RBS_ID] ,[RBS_DESCRIPTION] ,[RBS_INS_ID])\n" +
                "VALUES("+inputRate.RBS_ID+",'"+inputGroup.grp_description+"',"+inputGroup.ins_id+")";
    let rate_behaviour_step = "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 00:00 - 06:00',"+inputRate.RBS_ID+"	,1 ,"+inputRate.Even_RAT_ID+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['12AM_6AM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Fri 06:00 - 18:00',"+inputRate.RBS_ID+"	,2 ,"+inputRate.Day_RAT_ID+","+inputRate.Day_DAT_ID+" ,null ,null ,"+inputRate['6AM_6PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Sat-Sun 06:00 - 18:00',"+inputRate.RBS_ID+"	,3 ,"+inputRate.Weekend_RAT_ID+","+inputRate.Weekend_DAT_ID+" ,null ,null ,"+inputRate['6AM_6PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 18:00 - 24:00',"+inputRate.RBS_ID+"	,4 ,"+inputRate.Even_RAT_ID+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['6PM_12PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)"+
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 00:00 - 06:00',"+inputRate.RBS_ID+"	,5 ,"+inputRate.Even_RAT_ID+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['12AM_6AM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Fri 06:00 - 18:00',"+inputRate.RBS_ID+"	,6 ,"+inputRate.Day_RAT_ID+","+inputRate.Day_DAT_ID+" ,null ,null ,"+inputRate['6AM_6PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Sat-Sun 06:00 - 18:00',"+inputRate.RBS_ID+"	,7 ,"+inputRate.Weekend_RAT_ID+","+inputRate.Weekend_DAT_ID+" ,null ,null ,"+inputRate['6AM_6PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 18:00 - 24:00',"+inputRate.RBS_ID+"	,8 ,"+inputRate.Even_RAT_ID+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['6PM_12PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)"+
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 17:00 - 24:00',"+inputRate.RBS_ID+"	,9 ,"+(inputRate.Even_RAT_ID+2)+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['5PM_12PM_DAH_ID']+" ,null ,null ,0 ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)"+
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 00:00 - 06:00',"+inputRate.RBS_ID+"	,10 ,"+(inputRate.Even_RAT_ID+2)+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['12AM_6AM_DAH_ID']+" ,null ,null ,1 ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Fri 06:00 - 18:00',"+inputRate.RBS_ID+"	,11 ,"+inputRate.Day_RAT_ID+","+inputRate.Day_DAT_ID+" ,null ,null ,"+inputRate['6AM_6PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Sat-Sun 06:00 - 18:00',"+inputRate.RBS_ID+"	,12 ,"+inputRate.Weekend_RAT_ID+","+inputRate.Weekend_DAT_ID+" ,null ,null ,"+inputRate['6AM_6PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n" +
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 18:00 - 24:00',"+inputRate.RBS_ID+"	,13 ,"+inputRate.Even_RAT_ID+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['6PM_12PM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)"+
                "INSERT INTO [dbo].[RATE_BEHAVIOR_STEP] ([RBSS_DESCRIPTION] ,[RBSS_RBS_ID] ,[RBSS_ORDER] ,[RBSS_RAT_ID] ,[RBSS_DAT_ID] ,[RBSS_DEX_ID] ,[RBSS_DATE] ,[RBSS_DAH],[RBSS_INI_HOUR] ,[RBSS_END_HOUR] ,[RBSS_DAY_NUM_OF_APPLICATION] ,[RBSS_INI_APPLY_DATE] ,[RBSS_END_APPLY_DATE] ,[RBSS_ALLOW_JUMP_NEXT_BLOCK] ,[RBSS_CONDITIONAL_VALUE_FOR_NEXT_BLOCK] ,[RBSS_RESET_TIME_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_RESET_AMOUNT_WHEN_JUMP_NEXT_BLOCK] ,[RBSS_ROUND_FINAL_PARKING_TO_STEP_END])\n" +
                "VALUES ('"+inputGroup.grp_description+" Mon-Sun 00:00 - 06:00',"+inputRate.RBS_ID+"	,14 ,"+inputRate.Even_RAT_ID+","+inputRate.Even_DAT_ID+" ,null ,null ,"+inputRate['12AM_6AM_DAH_ID']+" ,null ,null ,null ,'2021-12-01 00:00:00.000' ,'2050-12-01 00:00:00.000' ,1 ,null ,0 ,0 ,0)\n\n";
    
    let tariff_constraint_set = "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS] ([TCS_ID] ,[TCS_DESCRIPTION] ,[TCS_INS_ID])\n" +
                "VALUES ("+inputRate.Day_TCS_ID+",'"+inputGroup.grp_description+" Mon-Fri Rate',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS] ([TCS_ID] ,[TCS_DESCRIPTION] ,[TCS_INS_ID])\n" +
                "VALUES ("+inputRate.Even_TCS_ID+",'"+inputGroup.grp_description+" Evening Rate',"+inputGroup.ins_id+")\n\n" +
                "INSERT INTO [dbo].[TARIFF_CONSTRAINTS_SETS] ([TCS_ID] ,[TCS_DESCRIPTION] ,[TCS_INS_ID])\n" +
                "VALUES ("+inputRate.Weekend_TCS_ID+",'"+inputGroup.grp_description+" Weekend Rate',"+inputGroup.ins_id+")";
    
    let tariff_constraint_enteries = "INSERT INTO [dbo].[TARIFF_CONSTRAINT_ENTRIES] ([TCE_ID],[TCE_DESCRIPTION],[TCE_TCS_ID],[TCE_GRP_ID],[TCE_GRPT_ID],[TCE_MAX_PARKING_TIME],[TCE_MIN_PARKING_AMOUNT],[TCE_MAX_PARKING_AMOUNT],[TCE_PARALEL_PARKING_ALLOWED],[TCE_QUERY_HISTORY_OPERATIONS],[TCE_COURTESY_TIME_IN_EXTENSION],[TCE_COURTESY_TIME_MUST_BE_PAID],[TCE_ALLOW_EXTENSIONS],[TCE_ALLOW_REFUNDS],[TCE_EXTENSION_MAX_SLIDING_WINDOW],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_EXTENSIONS],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_REFUNDS],[TCE_MIN_REENTRY_TIME],[TCE_NUM_TARIFF_MINUTES_FOR_NOT_APP_REENTRY],[TCE_INTRA_GROUP_PARK],[TCE_INTRA_GROUP_PARK_COURTESY_TIME])\n"+
                "VALUES("+inputRate.Day_TCE_ID+",'"+inputGroup.grp_description+" Mon-Fri Rate',"+inputRate.Day_TCS_ID+" ,null,null,1440,"+inputRate.day_min_rate_time+","+(parseInt(inputRate.day_max_rate_time)+parseInt(inputRate.evening_rate)+parseInt(inputRate.weekend_rate))+",0,2,0,0,1,0,1,0,0,0,0,0,0)\n\n" +
                "INSERT INTO [dbo].[TARIFF_CONSTRAINT_ENTRIES] ([TCE_ID],[TCE_DESCRIPTION],[TCE_TCS_ID],[TCE_GRP_ID],[TCE_GRPT_ID],[TCE_MAX_PARKING_TIME],[TCE_MIN_PARKING_AMOUNT],[TCE_MAX_PARKING_AMOUNT],[TCE_PARALEL_PARKING_ALLOWED],[TCE_QUERY_HISTORY_OPERATIONS],[TCE_COURTESY_TIME_IN_EXTENSION],[TCE_COURTESY_TIME_MUST_BE_PAID],[TCE_ALLOW_EXTENSIONS],[TCE_ALLOW_REFUNDS],[TCE_EXTENSION_MAX_SLIDING_WINDOW],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_EXTENSIONS],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_REFUNDS],[TCE_MIN_REENTRY_TIME],[TCE_NUM_TARIFF_MINUTES_FOR_NOT_APP_REENTRY],[TCE_INTRA_GROUP_PARK],[TCE_INTRA_GROUP_PARK_COURTESY_TIME])\n"+
                "VALUES("+inputRate.Even_TCE_ID+",'"+inputGroup.grp_description+" Evening Rate',"+inputRate.Even_TCS_ID+" ,null,null,1440,"+(inputRate.evening_rate)+","+(parseInt(inputRate.day_max_rate_time)+parseInt(inputRate.evening_rate)+parseInt(inputRate.weekend_rate))+",0,2,0,0,1,0,1,0,0,0,0,0,0)\n\n" +
                "INSERT INTO [dbo].[TARIFF_CONSTRAINT_ENTRIES] ([TCE_ID],[TCE_DESCRIPTION],[TCE_TCS_ID],[TCE_GRP_ID],[TCE_GRPT_ID],[TCE_MAX_PARKING_TIME],[TCE_MIN_PARKING_AMOUNT],[TCE_MAX_PARKING_AMOUNT],[TCE_PARALEL_PARKING_ALLOWED],[TCE_QUERY_HISTORY_OPERATIONS],[TCE_COURTESY_TIME_IN_EXTENSION],[TCE_COURTESY_TIME_MUST_BE_PAID],[TCE_ALLOW_EXTENSIONS],[TCE_ALLOW_REFUNDS],[TCE_EXTENSION_MAX_SLIDING_WINDOW],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_EXTENSIONS],[TCE_MIN_AMOUNT_TO_BE_CHARGED_IN_REFUNDS],[TCE_MIN_REENTRY_TIME],[TCE_NUM_TARIFF_MINUTES_FOR_NOT_APP_REENTRY],[TCE_INTRA_GROUP_PARK],[TCE_INTRA_GROUP_PARK_COURTESY_TIME])\n"+
                "VALUES("+inputRate.Weekend_TCE_ID+",'"+inputGroup.grp_description+" Weekend Rate',"+inputRate.Weekend_TCS_ID+" ,null,null,1440,"+(inputRate.weekend_rate)+","+(parseInt(inputRate.day_max_rate_time)+parseInt(inputRate.evening_rate)+parseInt(inputRate.weekend_rate))+",0,2,0,0,1,0,1,0,0,0,0,0,0)\n\n";

    let tariff_definition_rules = "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Mon-Sun 00:00 - 06:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.Even_TCS_ID+",'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.Even_DAT_ID+","+inputRate['12AM_6AM_DAH_ID']+",null,null)\n\n" +
                "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Mon-Fri 06:00 - 17:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.Day_TCS_ID+",'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.Day_DAT_ID+","+inputRate['6AM_5PM_DAH_ID']+",null,null)\n\n" +
                "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Sat-Sun 06:00 - 17:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.Weekend_TCS_ID+",'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.Weekend_DAT_ID+","+inputRate['6AM_5PM_DAH_ID']+",null,null)\n\n" +
                "INSERT INTO [dbo].[TARIFFS_DEFINITION_RULES] ([TARDR_DESCRIPTON],[TARDR_TAR_ID],[TARDR_GRP_ID],[TARDR_GRPT_ID],[TARDR_RBS_ID],[TARDR_TCS_ID],[TARDR_INI_APPLY_DATE],[TARDR_END_APPLY_DATE],[TARDR_DAT_ID],[TARDR_DAH_ID],[TARDR_IN_DAY_INI_HOUR],[TARDR_IN_DAY_END_HOUR])\n"+
                "VALUES('"+inputGroup.grp_description+" Mon-Sun 17:00-24:00',"+inputRate.TAR_ID+"	,"+inputGroup.grp_id+" ,null ,"+inputRate.RBS_ID+","+inputRate.Even_TCS_ID+",'2021-11-01 00:00:00.000','2050-11-01 00:00:00.000',"+inputRate.Even_DAT_ID+","+inputRate['5PM_12PM_DAH_ID']+",null,null)";

    let tariff_application_rules = "INSERT INTO [dbo].[TARIFFS_APPLICATION_RULES]([TAPR_DESCRIPTON],[TAPR_TAR_ID],[TAPR_UNI_ID],[TAPR_GRP_ID],[TAPR_GRPT_ID],[TAPR_INI_APPLY_DATE],[TAPR_END_APPLY_DATE],[TAPR_DAT_ID],[TAPR_DAH_ID],[TARP_IN_DAY_INI_HOUR],[TARP_IN_DAY_END_HOUR],[TAPR_IN_YEAR_INI_DATE],[TAPR_IN_YEAR_END_DATE],[TAPR_SHOW_ORDER],[TAPR_ALLOW_USER_SELECT],[TAPR_INSERT_PLATE],[TAPR_SPACE_MANAGMENT_TYPE],[TAPR_TCS_ID],[TAPR_PLATE_TARIFF_ASSIGN_TYPE],[TAPR_TIMEAMOUNT_SEL_TYPE],[TAPR_PAY_MODE],[TAPR_INC1],[TAPR_INC2],[TAPR_INC3],[TAPR_INC4],[TAPR_INC5],[TAPR_DEC1],[TAPR_DEC2],[TAPR_DEC3],[TAPR_DEC4],[TAPR_DEC5],[TAPR_ACCESS_CARD_TYPE],[TAPR_ACCESS_CARD_NUM],[TAPR_CONNECTION_REQUIRED],[TAPR_ALLOW_REMOTE_EXTENSION],[TAPR_TARIFF_ENGINE],[TAPR_BNF_ID],[TAPR_ALLOW_VOUCHERS],[TAPR_VOUCHER_ASSIGN_TYPE],[TAPR_VOUCHER_ASSIGN_VALUE],[TAPR_PERMITTED_ASSIGNED_TARIFFS],[TAPR_AUTO_ASSIGNED_TARIFFS],[TAPR_COMPUTESTEPS_TIMEAMOUNT],[TAPR_COMPUTESTEPS_OFFSET],[TAPR_ALLOW_STEP_OVERPAY],[TAPR_ALLOWED_PAYMENT_METHODS])\n"+
              "VALUES('"+inputGroup.grp_description+"',"+inputRate.TAR_ID+",null,"+inputGroup.grp_id+",null,'2019-03-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,null,null,null,null,1,1,1,0,"+inputRate.Day_TCS_ID+",1,0,0,5,10,30,60,120,5,10,30,60,120,null,null,null,0,5,null,0,null,null,null,null,null,null,0,null)";

    let integra_tarif = "INSERT INTO [dbo].[TARIFFS]([TAR_ID],[TAR_DESCRIPTION],[TAR_LIT_ID],[TAR_QUERY_EXT_ID],[TAR_EXT1_ID],[TAR_EXT2_ID],[TAR_EXT3_ID],[TAR_ID_FOR_EXT_OPS],[TAR_INS_ID],[TAR_MIN_COIN],[TAR_TYPE],[TAR_PERMIT_RENEWAL_DAY],[TAR_MAX_PLATES],[TAR_MAX_PERMITS_ONCE],[TAR_BEHAVIOR],[TAR_APP_MIN_VERSION],[TAR_APP_MAX_VERSION],[TAR_SERTYP_ID],[TAR_AUTOSTART],[TAR_RESTART_TARIFF],[TAR_NOTICE_CHARGES_NOW],[TAR_NOTICE_CHARGES_NOW_LIT_ID],[TAR_SHOPKEEPER_BEHAVIOR_TYPE])\n"+
         "VALUES("+inputRate.integra_tar_id+",'"+inputGroup.grp_description+"',"+(inputRate.integra_lit_id+1)+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.TAR_ID+","+inputRate.integra_ins_id+",null,0,null,1,null,0,null,null,null,null,null,1,100,0)";
        
    let integra_tarif_in_group = "INSERT INTO [dbo].[TARIFFS_IN_GROUPS]([TARGR_GRP_ID],[TARGR_GRPT_ID],[TARGR_TAR_ID],[TARGR_TIME_STEPS_VALUE],[TARGR_LIT_ID],[TARGR_USER_SELECTABLE],[TARGR_INI_APPLY_DATE],[TARGR_END_APPLY_DATE],[TARGR_STEP1_MIN],[TARGR_STEP1_LIT_ID],[TARGR_STEP1_ENABLED],[TARGR_STEP2_MIN],[TARGR_STEP2_LIT_ID],[TARGR_STEP2_ENABLED],[TARGR_STEP3_MIN],[TARGR_STEP3_LIT_ID],[TARGR_STEP3_ENABLED],[TARGR_STEP4_MIN],[TARGR_STEP4_LIT_ID],[TARGR_STEP4_ENABLED],[TARGR_STEP5_MIN],[TARGR_STEP5_LIT_ID],[TARGR_STEP5_ENABLED],[TARGR_STEP6_MIN],[TARGR_STEP6_LIT_ID],[TARGR_STEP6_ENABLED],[TARGR_STEP7_MIN],[TARGR_STEP7_LIT_ID],[TARGR_STEP7_ENABLED],[TARGR_STEP8_MIN],[TARGR_STEP8_LIT_ID],[TARGR_STEP8_ENABLED],[TARGR_STEP9_MIN],[TARGR_STEP9_LIT_ID],[TARGR_STEP9_ENABLED],[TARGR_STEP10_MIN],[TARGR_STEP10_LIT_ID],[TARGR_STEP10_ENABLED],[TARGR_LAYOUT],[TARGR_PARKING_MODE],[TARGR_TAR_INFO2_LIT_ID],[TARGR_TAR_INFO3_LIT_ID],[TARGR_TAR_INFO4_LIT_ID],[TARGR_TAR_INFO5_LIT_ID],[TARGR_TAR_INFO6_LIT_ID],[TARGR_TAR_INFO7_LIT_ID],[TARGR_TAR_INFO8_LIT_ID],[TARGR_TAR_INFO9_LIT_ID],[TARGR_TAR_INFO10_LIT_ID],[TARGR_INCLUDE_ZERO_STEP],[TARGR_TIME_DEF_STEP],[TARGR_POLYGON_SHOW],[TARGR_POLYGON_COLOUR],[TARGR_POLYGON_Z],[TARGR_POLYGON_MAP_DESCRIPTION])\n" +
    "VALUES("+inputRate.integra_grp_id+",null,"+inputRate.integra_tar_id+",5,"+(inputRate.integra_lit_id+1)+",1,'2021-06-01 00:00:00.000','2050-01-01 00:00:00.000',null,null,1,null,null,1,null,null,1,null,null,0,null,null,0,null,null,0,null,null,0,null,null,0,null,0,null,null,null,1,1,1,null,null,null,null,null,null,null,null,null,0,null,null,null,null,null)";

    let ticket_grhi = "INSERT INTO GROUPS_HIERARCHY (GRHI_ID, GRHI_GRP_ID, GRHI_INI_APPLY_DATE, GRHI_END_APPLY_DATE)\n"+
    "VALUES ("+inputRate.ticket_grhi_id+","+inputRate.ticket_grp_id+", TO_DATE('12/16/2021', 'mm/dd/yyyy') ,TO_DATE('1/1/2050', 'mm/dd/yyyy'));"
    
    let lastQue = '';
    let queryObj = {
      tarif: tarif, 
      rate_type: rate_type, 
      rate_steps: rate_steps,
      rate_behaviour_set: rate_behaviour_set, 
      day_hour_interval: day_hour_interval,
      day_types: day_types, 
      rate_behaviour_step: rate_behaviour_step, 
      tariff_constraint_set: tariff_constraint_set,
      tariff_constraint_enteries: tariff_constraint_enteries, 
      tariff_definition_rules: tariff_definition_rules, 
      tariff_application_rules: tariff_application_rules
    }
    let integraObj = {
      // integra_lit: integra_lit,
      integra_tarif: integra_tarif,
      integra_tarif_in_group: integra_tarif_in_group
    }
    let ticketObj = {
      ticket_grhi: ticket_grhi
    }
    return {
      query: lastQue.concat(tarif, rate_type, rate_steps, rate_behaviour_set, day_hour_interval,
       day_types, rate_behaviour_step, tariff_constraint_set, tariff_constraint_enteries, tariff_definition_rules, 
       tariff_application_rules),
      queryObj: queryObj,
      integraObj: integraObj,
      ticketObj: ticketObj
    }
  }

  UpdateRate(rate_steps,tcs_ids){
    console.log(rate_steps)
    let update_rate_query = "";
    rate_steps.map((x)=>{
          let update_rate = "UPDATE [dbo].[RATE_TYPES] SET [RAT_DESCRIPTION] = '"+x.rat_desc+"' WHERE RAT_ID = "+x.rat_id;
          update_rate_query = update_rate_query.concat("\n\n", update_rate)
    });
    let del_steps_query = "";
    rate_steps.map((x)=>{
          let del_step = "DELETE [RATE_STEPS] WHERE RAS_RAT_ID = "+x.rat_id;
          del_steps_query = del_steps_query.concat("\n\n", del_step)
    })
    let rate_steps_query = "";
    rate_steps.map((x)=>{
      if(x.max_rate !== ""){
        let steps_by_rate = parseInt(x.max_rate/x.min_rate);
        steps_by_rate = (x.max_rate/x.min_rate% 1) ? parseInt(x.max_rate/x.min_rate)+1 : parseInt(x.max_rate/x.min_rate)
        for(let i = 0; i < steps_by_rate; i++){
          let time = x.min_time * (i+1);
          let rate = x.min_rate * (i+1)
          if(rate >= x.max_rate){
            time = x.max_time;
            rate = x.max_rate;
          }
          let rate_step = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                    "VALUES ('$"+(rate)/100+" = "+time+" min',"+x.rat_id+","+(i+1)+","+(rate)+","+time+",3 ,0)";
          rate_steps_query = rate_steps_query.concat("\n\n", rate_step)
        }
      }else{
          let rate_step = "INSERT INTO [dbo].[RATE_STEPS] ([RAS_DESCRIPTION] ,[RAS_RAT_ID] ,[RAS_ORDER] ,[RAS_AMOUNT] ,[RAS_NUM_MINUTES] ,[RAS_INTERVAL_VALUE_TYPE] ,[RAS_INTERMEDIATE_VALUES_VALID])\n" +
                    "VALUES ('$"+(x.min_rate)/100+" = "+x.min_time+" min',"+x.rat_id+","+1+","+x.min_rate+","+x.min_time+",3 ,0)";
          rate_steps_query = rate_steps_query.concat("\n\n", rate_step)
      }
    })
    let max_amount = 0;
    rate_steps.map((x)=>{
      max_amount = max_amount + ((x.max_rate !== "") ? parseInt(x.max_rate) : parseInt(x.min_rate));      
    })
    console.log(max_amount)
    let tce_query = ""
    tcs_ids.map((x)=>{
          let tce_update = "UPDATE [TARIFF_CONSTRAINT_ENTRIES] SET TCE_MAX_PARKING_AMOUNT ="+max_amount+" WHERE TCE_TCS_ID = "+x;
          tce_query = tce_query.concat("\n\n", tce_update)
    })
    return {
      del_steps_query : del_steps_query,
      update_rate_query : update_rate_query,
      rate_steps_query : rate_steps_query,
      tce_query : tce_query
    };
  }

}
let rateQueryGenerator = new RateQueryGenerator();
export default rateQueryGenerator;
